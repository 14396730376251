import { useState } from 'react';

export interface CanvasComponent {
  id: number;
  type: string;
  x: number;
  y: number;
  src?: string; // Only for image type
  width?: number; // Only for image type
  height?: number; // Only for image type
}

const useCanvas = () => {
  const [components, setComponents] = useState<CanvasComponent[]>([        
    {
    id: 999,  // Unique ID for welcome image
    type: 'image',
    x: 150,
    y: 30,
    src: '/images/get_started_sticky.png',
    width: 157,
    height: 158,
  }
  ]);

  const removeWelcomeImage = () => {
    setComponents((prevComponents) => prevComponents.filter(c => c.id !== 999));
  };

  const addComponent = (type: string, src?: string, width?: number, height?: number, x?: number, y?: number) => {
    const newComponent: CanvasComponent = {
      id: components.length + 10,
      type,
      x: x || 50 + Math.random() * 300, // Initial position, can be adjusted later
      y: y || 50 + Math.random() * 500,
      ...(type === 'image' && src ? { src, width, height } : {}), // Add image properties if type is 'image'
    };
    setComponents((prevComponents) => [...prevComponents, newComponent]);
  };

  return {
    components,
    addComponent,
    setComponents,
    removeWelcomeImage,
  };
};

export default useCanvas;
