import React, { useState, useRef, useEffect } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import './Toolbar.css';
import ElementsPopover from './popovers/ElementsPopover';
import Modal from 'react-modal';
import { Popover } from './popovers/Popover';

Modal.setAppElement('#root'); // Set your app root for accessibility

interface ToolbarProps {
  onAddComponent: (type: string, src?: string, width?: number, height?: number, x?: number, y?: number) => void;
  onLoadConfig: () => void;
  onToolbarAction: () => void; 
}

interface PopoverButtonProps {
  type: string;
  icon: string;
  label: string;
  onAddComponent?: (type: string, src?: string) => void;
  isVisible: boolean;
  onTogglePopover: () => void;
}

const PopoverButton: React.FC<PopoverButtonProps> = ({
  type,
  icon,
  onAddComponent,
  isVisible,
  onTogglePopover,
}) => {
  const aiPanelRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (type === 'ai' && isVisible) {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          aiPanelRef.current &&
          !aiPanelRef.current.contains(event.target as Node) &&
          buttonRef.current &&
          !buttonRef.current.contains(event.target as Node)
        ) {
          onTogglePopover();
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [isVisible, type, onTogglePopover]);

  const handleAddComponent = (componentType: string, src?: string) => {
    onAddComponent?.(componentType, src);
    onTogglePopover(); // Close the popover after adding a component
  };

  if (type === 'ai' && isVisible) {
    // Special handling for the AI button with its own fixed positioning
    return (
      <div className="popover-wrapper">
        <button ref={buttonRef} className="ai-button" onClick={onTogglePopover}>
          <img src={icon} alt={type} className="selected tool-icon" />
        </button>
        <div
          ref={aiPanelRef}
          className="ai-panel"
          style={{
            position: 'fixed',
            top: '61px',
            right: 0,
            width: '380px',
            backgroundColor: 'white',
            zIndex: 1000,
            borderRadius: '14px',
          }}
        >
          <img src="/images/ai.png" alt="ai" style={{ width: '100%' }} />
        </div>
      </div>
    );
  }

  if (type === 'comments') {
    // Special case for comments button that directly adds a comment component on click
    return (
      <div className="popover-wrapper">
        <button
          className="tool-button"
          onClick={() => handleAddComponent(type, '/images/comment.png')}
        >
          <img src={icon} alt={type} className="tool-icon" />
        </button>
      </div>
    );
  }

  // Regular popover for other buttons
  return (
    <div className="popover-wrapper">
      <Tippy
        content={
          type === 'table' ? (
            <ElementsPopover onAddComponent={(componentType) => handleAddComponent(componentType)} />
          ) : (
            <Popover onAddComponent={() => handleAddComponent(type)} image={`/assets/temp/${type}.png`} />
          )
        }
        visible={isVisible}
        onClickOutside={() => onTogglePopover()} // Close popover when clicking outside
        placement="right"
        offset={[20, 10]}
        interactive={true}
        arrow={false} // Disable the arrow
        className="custom-tippy" // Add custom class to style background
      >
        <button
          className="tool-button"
          onClick={onTogglePopover}
        >
          <img src={icon} alt={type} className={isVisible ? "selected tool-icon" : "tool-icon"} />
        </button>
      </Tippy>
    </div>
  );
};

const Toolbar: React.FC<ToolbarProps> = ({ onAddComponent, onLoadConfig, onToolbarAction }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState<string | null>(null);

  const togglePopover = (type: string) => {
    setPopoverVisible((prev) => (prev === type ? null : type));
  };

  const buttons = [
    { type: 'text', icon: '/assets/text.svg', label: 'Texts', onAddComponent: () => { onAddComponent('text'); onToolbarAction(); } },
    { type: 'draw', icon: '/assets/draw.svg', label: 'Draws', onAddComponent: () => { onAddComponent('draw'); onToolbarAction(); } },
    { type: 'table', icon: '/assets/elements.svg', label: 'Elements list', onAddComponent },
    { type: 'shape', icon: '/assets/shapes.svg', label: 'Shapes list', onAddComponent: () => {onAddComponent('circle'); onToolbarAction(); } },
    { type: 'sticky', icon: '/assets/sticky.svg', label: 'Sticky list', onAddComponent: () => {onAddComponent('image', '/images/sticky.png', 109, 112); onToolbarAction(); } },
    { type: 'comments', icon: '/assets/comment.svg', label: 'Comments', onAddComponent: () => {onAddComponent('image', '/images/comment.png'); onToolbarAction(); } }, // No popover needed for comments, handled directly
    { type: 'embed', icon: '/assets/embed.svg', label: 'Embed', onAddComponent: () => {
      onAddComponent('image', '/images/embed1.png', undefined, undefined, 50, 50); 
      onAddComponent('image', '/images/embed2.png', undefined, undefined, 220, 50);
      onToolbarAction() 
     }},
    { type: 'connected', icon: '/assets/connected.svg', label: 'Connected', onAddComponent: () => {onAddComponent('image', '/images/connected_bank.png'); onToolbarAction(); } },
    { type: 'ai', icon: '/assets/ai.svg', label: 'AI' }, // AI button handled specially
  ];

  const customButtons = [
    { type: 'rectangle', icon: '/assets/templates.svg', label: 'Templates list' },
  ];

  return (
    <div className="toolbar">
      <PopoverButton
        key={customButtons[0].type}
        type={customButtons[0].type}
        icon={customButtons[0].icon}
        label={customButtons[0].label}
        onAddComponent={() => setModalIsOpen(true)}
        isVisible={false}
        onTogglePopover={() => setModalIsOpen(true)}
      />
      {buttons.map((button) => (
        <PopoverButton
          key={button.type}
          type={button.type}
          icon={button.icon}
          label={button.label}
          onAddComponent={button?.onAddComponent}
          isVisible={popoverVisible === button.type}
          onTogglePopover={() => togglePopover(button.type)}
        />
      ))}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={{
          content: {
            maxWidth: '1250px',
            height: '100vh',
            borderRadius: '16px',
            border: 'none',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            overflow: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
        shouldCloseOnOverlayClick={true}
      >
        <img
          src="/assets/temp/templates.png"
          alt="elements"
          style={{ width: '100%' }}
          onClick={() => {
            onLoadConfig(); // Load the configuration on image click
            setModalIsOpen(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default Toolbar;
